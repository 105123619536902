<template>
    <div class="wid1200 expert-page">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        <div class="scholar-wrap">
            <div class="scholar">
                <div class="scholar-img">
                    <img v-if="expertObj && expertObj.picture" :src="expertObj.picture" alt="" />
                    <img v-else alt="">
                </div>
                <div class="scholar-info">
                    <h5>{{expertObj.name}}</h5>
                    <div class="university-scholar">
                        {{expertObj.orgName}}
                    </div>
                    <div class="content-scholar" v-if="expertObj && expertObj.description">
                        {{expertObj.description}}
                    </div>
                </div>
            </div>
            <div class="scholar-chart ">
                <expert-chart :expertChart="expertChart"></expert-chart>
                <!-- <div class="scholar-chart-num">
                    <p>总计</p>
                    <h5>{{expertChart.total}}</h5>
                </div> -->
            </div>
        </div>
        <div class="article-wrap" v-if="articleData && articleData.total>0">
            <com-article :articleData="articleData" :page-name="pageName" @pageChange="articleChange" />
        </div>
        <div class="book-wrap" v-if="bookData && bookData.total>0">
            <com-book :bookData="bookData" :page-name="pageName" @pageChange="bookChange" />
        </div>

        <div class="wid1200 source-wrap" v-if="maData && maData.length>0">
            <div class="com-title">
                <div>
                    <h5>课程材料</h5>
                </div>
                <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
            </div>
            <ul class="source">
                <li v-for="i in maData" :key="i.id">
                    <span style="margin-right: 10px;">
                        {{i.appendixType}}
                        <!-- {{i.title.slice(i.title.length-3, i.title.length)}} -->
                    </span>
                    <p :style="{cursor:(i.appendix ? 'pointer':'auto')}" @click="download(i)">{{i.title}}</p>
                </li>
            </ul>
            <div class="pagination-wrap">
                <el-pagination @current-change="handleCurrentChangeMe" :current-page="materialNum" :page-size="5"
                    layout="total, prev, pager, next, jumper" :total="materialTotal">
                </el-pagination>
            </div>
        </div>
        <div class="wid1200 source-wrap">
            <div class="com-title">
                <div>
                    <h5>其他资源</h5>
                </div>
                <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
            </div>
            <ul class="source">
                <li v-for="i in reData" :key="i.id">
                    <span style="margin-right: 10px;">
                        {{i.appendixType}}
                        <!-- {{i.title.slice(i.title.length-3, i.title.length)}} -->
                    </span>
                    <p :style="{cursor:(i.appendix ? 'pointer':'auto')}" @click="download(i)">{{i.title}}</p>
                </li>
            </ul>
            <div class="pagination-wrap">
                <el-pagination @current-change="handleCurrentChangeRe" :current-page="resourcesNum" :page-size="5"
                    layout="total, prev, pager, next, jumper" :total="resourceTotal">
                </el-pagination>
            </div>
        </div>
        <div class="relevant-expert-ins-wrap">
            <div class="relevant-expert-wrap com-relevant">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/experticon.png" alt="" />
                        <h5>相关学者</h5>
                    </div>
                    <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
                </div>
                <div class="relevant-expert">
                    <div class="relevant-expert-img">
                        <img v-if="expertData[0] && expertData[0].picture" :src="expertData[0].picture" alt=""
                            @click="goExp(expertData[0].relateId)" />
                        <img v-else alt="" />
                    </div>
                    <div class="relevant-expert-info">
                        <!-- scholarName   relateName -->
                        <h5 v-if="expertData[0] && expertData[0].scholarName" @click="goExp(expertData[0].relateId)">
                            {{expertData[0].scholarName}}</h5>
                        <p v-if="expertData[0] && expertData[0].description">
                            {{expertData[0].description}}
                        </p>
                    </div>
                </div>
                <ul>
                    <li v-for="(i, ind) in expertData" :key="i.id" v-if="ind>0" @click="goExp(i.relateId)">
                        {{i.scholarName}}</li>

                </ul>
            </div>

            <div class="relevant-ins-wrap com-relevant">
                <div class="com-title">
                    <div>
                        <img class="title-img" src="../assets/img/insicon.png" alt="" />
                        <h5>相关机构</h5>
                    </div>
                    <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
                </div>
                <div class="relevant-ins">
                    <div class="relevant-ins-img">
                        <img v-if="insData[0] && insData[0].picture" :src="insData[0].picture" alt="" />
                        <img v-else alt="" />
                    </div>
                    <div class="relevant-ins-info">
                        <h5 @click="goIns(insData[0].relateId)" v-if="insData[0]">{{insData[0].researchName}}</h5>
                        <p v-if="insData[0] && insData[0].description">
                            {{insData[0].description}}
                        </p>
                    </div>
                </div>
                <ul>
                    <li v-for="(i, ind) in insData" :key="i.id" v-if="ind>0" @click="goIns(i.relateId)">{{i.researchName}}
                    </li>

                </ul>
            </div>
        </div>

        <a id="downLaodAticle" style="display:none;" :href="'/admin/api/article/downLoad?id='+downloadId">下载阅读</a>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
            :append-to-body="trueDate">
            <div class="tips" v-if="codeNum == '1002'">
                <span v-if="paylist.length>0"  style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}} 元，请购买后再操作！</span>
                <span v-else  style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}} 元，账号余额不足请先充值。</span>
            </div>

            <span class="tips  com-tips" v-else-if="tips == '您还未登录，请先登录！'"  style="font-size: 16px;line-height: 30px;">
                您还未登录，请先<span class="com-login-tips"  @click="toLogin">登录</span>！
            </span>
            <span class="tips com-tips" v-else v-html="tips"></span>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="showBuy" v-if="codeNum == '1002'">去购买</el-button>
                <el-button type="small" @click="toLogin" v-if="codeNum == '0'&&!hasPay">请登录</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="dialogBuy" width="30%" :before-close="handleBuyClose"
            :append-to-body="trueDate">
            <div class="tips tipsleft">
                <span  style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}}元</span>
                <p  style="font-size: 16px;line-height: 30px;">支付方式：</p>
                <p v-for="(item, index) in balanceInfo" :key="index"  style="font-size: 16px;line-height: 30px;">
                    <el-radio v-model="radiobuy" :label="item.accounttype"  style="font-size: 16px;line-height: 30px;">
                        {{item.accounttype=='common'?'现金账户余额':'充值卡余额'}}{{item.accountbalance}}</el-radio>
                </p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="handleBuyClose">取消</el-button>
                <el-button type="small" @click="gobuy">支付</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="dialogVisibledow" width="30%" :before-close="handleClosedow"
            :append-to-body="trueDate">
            <span class="tips">{{tipsdow}}</span>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="dialogVisibledow = !dialogVisibledow">确定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import ComArticle from '../components/ComArticle'
import ComBook from '../components/ComBook'
import ExpertChart from '../components/ExpertChart'

import img1 from '../assets/img/book.png'

export default {
    name: '',
    components: { BreadCrumb, ComArticle, ComBook, ExpertChart },
    data() {
        return {
            pN: '',
            pageName: '学者个人学术主页',
            expertObj: {},
            downloadId: '',
            articleData: {
                title: '学术文章',
                articleList: [
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    }
                ]
            },
            bookData: {
                title: '图书',
                bookList: [
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    }
                ]
            },
            pageSize: 0,
            pageNum: 1,
            expertData: [],
            insData: [],
            materialNum: 1,
            resourcesNum: 1,
            materialTotal: 0,
            resourceTotal: 0,
            maData: [],
            reData: [],
            expertChart: {},

            dialogVisible: false,
            tips: '',
            paylist: [],
            balanceInfo: [],
            payPrice: '',
            radiobuy: '',
            codeNum: '',
            trueDate: true,
            hasPay: false,
            dialogVisibledow: false,
            tipsdow: '',
            shareWechat: false,
            dialogBuy: false
        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init() {
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }
            this.getData()
            this.getArticleBook('xswz') //文章
            this.getArticleBook('xszz') //著作
            this.getMaterialResources('kccl') //课程材料
            this.getMaterialResources('qtzy') //其他资源
        },


        download(item) {
            this.downloadId = item.id
            var _this = this
            // _trackData.push(['addaction','期刊主页', '全部下载按钮', {'标题': _this.detail.title}])
            var result = _this.getCheck()
            result.then(function(res) {
                if (res.data.code == 0) {
                    if (item.appendixType == 'pdf') {
                        window.open(item.appendix, '_blank')
                    }  else {
                        document.getElementById('downLaodAticle').click()
                    }
                        
                }
            }).catch(function(res) {
                _this.dialogVisible = true
                if (res.code == 401) {
                    //未登录
                    _this.tips = '您还未登录，请先登录！'
                }
                if (res.code == 1002) {
                    //无权限
                    _this.paylist = error.paylist
                    _this.balanceInfo = error.balanceInfo
                    _this.payPrice = error.price
                    _this.tips = '这个资源需要付费，请购买后查看！'
                }
                if (res.code == 500) {
                    //统一错误
                    _this.tips = res.msg
                }
            })
        },
        handleBuyClose() {
            this.dialogBuy = false
        },
        handleClosedow() {
            this.dialogVisibledow = false
        },
        handleClose() {
            this.dialogVisible = false
        },
        showBuy() {
            this.dialogBuy = !this.dialogBuy
        },
        encodeURI2(){
          var url = encodeURIComponent(window.location.origin + encodeURIComponent(window.location.pathname) + window.location.search + window.location.hash)
          console.log(url)
          return url;
        },
        toLogin() {
            window.location.href = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
        },
        gobuy() {
            let _this = this
            _this.winUrl = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
            _this.$http({
                method: 'get',
                url: '/admin/api/order/submit',
                params: {
                    contentId: _this.downloadId,
                    payment: _this.radiobuy,
                    resourceType: 'literature'
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.codeNum = 0
                    _this.codeNum2 = false
                    _this.dialogBuy = false
                    _this.hasPay = true
                    _this.tips = '支付完成！'
                } else {
                    _this.dialogVisibledow = true
                    _this.tipsdow = res.msg
                }
            })
        },
        getCheck(type) {
            let _this = this
            var promise = new Promise(function(resolve, reject) {
                _this.$http
                    .get('/admin/api/order/check', {
                        params: {
                            id: _this.downloadId,
                            type: type,
                            resourceType: 'literature'
                        },
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    })
                    .then(res => {
                        _this.codeNum = res.data.code
                        if (res.data.code == 0) {
                            resolve(res)
                        } else {
                            reject(res.data)
                        }
                    })
            })
            return promise
        },



        handleCurrentChangeMe(val) {
            this.materialNum = val
            this.getMaterialResources('kccl') //课程材料
        },
        handleCurrentChangeRe(val) {
            this.resourcesNum = val
            this.getMaterialResources('qtzy') //其他资源
        },
        articleChange(val) {
            this.pageNum = val
            // this.getList('xswz') //机构文章
            this.getArticleBook('xswz') //文章
            

        },
        bookChange(val) {
            // console.log(999, val)
            this.pageNum = val
            this.getArticleBook('xszz') //著作
        },
        goIns(id) {
            this.$router.push({ path: '/ins/i', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        goExp(id) {
            this.$router.push({ path: '/expert/e', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        getData() {
            var _this = this
            var curId
            if (this.$route.query.id) {
                curId = this.$route.query.id
            } else {
                curId = sessionStorage.getItem('idExpert')
            }
            this.$http({
                methods: 'get',
                url: '/admin/api/scholar/archives?id=' + curId
            }).then(res => {
                if (res.data.code == 0) {
                    var data = res.data.data

                    _this.expertObj = data
                    _this.pageName = data.name
                    _this.expertData = data.scholarList
                    _this.insData = data.researchList
                    var curObj = {
                        wzList: data.wzList,
                        zzList: data.zzList,
                        qtList: data.qtList,
                        kcList: data.kcList,
                        total: data.total
                    }
                    _this.expertChart = curObj
                }
            })
        },
        getArticleBook(type) {
            var _this = this
            if (type == 'xswz') {
                this.pageSize = 14
            } else if (type == 'xszz') {
                this.pageSize = 10
            }

            var curId
            if (this.$route.query.id) {
                curId = this.$route.query.id
            } else {
                curId = sessionStorage.getItem('idExpert')
            }

            this.$http({
                method: 'get',
                url: '/admin/api/scholar/list',
                params: {
                    id: curId,
                    subject: type,
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                    // year: '',
                    // sort: 'asc'
                }
            }).then(res => {
                if (res.data.code == 0) {
                    if (type == 'xswz') {
                        _this.articleData.articleList = res.data.data.rows
                        _this.articleData.total = res.data.data.total
                    } else if (type == 'xszz') {
                        _this.bookData.bookList = res.data.data.rows
                        _this.bookData.total = res.data.data.total
                    }
                }
            })
        },
        getMaterialResources(type) {
            var _this = this
            var num
            if (type == 'kccl') {
                num = this.materialNum
            } else if (type == 'qtzy') {
                num = this.resourcesNum
            }

            var curId
            if (this.$route.query.id) {
                curId = this.$route.query.id
            } else {
                curId = sessionStorage.getItem('idExpert')
            }

            this.$http({
                method: 'get',
                url: '/admin/api/scholar/list',
                params: {
                    id: curId,
                    subject: type,
                    pageSize: 5,
                    pageNum: num
                    // year: '',
                    // sort: 'asc'
                }
            }).then(res => {
                if (res.data.code == 0) {
                    if (type == 'kccl') {
                        _this.maData = res.data.data.rows
                        _this.materialTotal = res.data.data.total
                    } else if (type == 'qtzy') {
                        _this.reData = res.data.data.rows
                        _this.resourceTotal = res.data.data.total
                        // console.log('reData 其他资源', _this.reData)
                    }
                    // if (type == 'xswz') {
                    //     _this.articleData.articleList = res.data.data.rows
                    //     _this.articleData.total = res.data.data.total
                    // } else if (type == 'xszz') {
                    //     _this.bookData.bookList = res.data.data.rows
                    //     _this.bookData.total = res.data.data.total
                    // }
                }
            })
        }
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.expert-page {
    .scholar-wrap {
        padding: 10px 30px 0;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        .scholar {
            display: flex;
            margin-top: 21px;
            .scholar-img {
                margin-right: 30px;
                margin-bottom: 29px;
                img {
                    width: 200px;
                    height: 260px;
                    box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
                }
            }
            .scholar-info {
                flex: 1;
                h5 {
                    font-size: 30px;
                    line-height: 47px;
                    margin-bottom: 5px;
                }
                .university-scholar {
                    color: #666666;
                    font-size: 14px;
                    line-height: 14px;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #e5e5e5;
                    margin-bottom: 14px;
                }
                .content-scholar {
                    line-height: 28px;
                    font-size: 16px;
                    // text-overflow: -o-ellipsis-lastline;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 6;
                    // line-clamp: 6;
                    // -webkit-box-orient: vertical;
                }
            }
        }
        .scholar-chart {
            width: 1138px;
            height: 270px;
            border: 1px solid #e6e6e6;
            margin-bottom: 30px;
            margin-top: 10px;
            display: flex;
            position: relative;
            .scholar-chart-num {
                position: absolute;
                right: 0;
                top: 0;
                margin-top: 90px;
                width: 210px;
                height: 180px;
                display: flex;
                flex-direction: column;
                p {
                    font-size: 26px;
                    color: #333;
                    margin-bottom: 20px;
                    text-align: center;
                    margin-top: 20px;
                }
                h5 {
                    font-size: 50px;
                    line-height: 50px;
                    color: #333;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .article-wrap {
        padding: 10px 30px 0;
        margin: 26px auto;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .book-wrap {
        padding: 10px 30px 0;
        margin-bottom: 26px;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .source-wrap {
        margin-bottom: 25px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        padding: 10px 30px 26px;
        box-sizing: border-box;
        .com-title {
            border-bottom: none;
            margin-bottom: 10px;
        }
        .source {
            li {
                width: 1138px;
                display: flex;
                align-items: center;
                padding: 16px 0 15px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                border-bottom: 1px dashed #e6e6e6;
                span {
                    background: #bd1a2d;
                    width: 26px;
                    height: 26px;
                    line-height: 26px;
                    color: #fff;
                    font-size: 10px;
                    text-align: center;
                    border-radius: 4px;
                }
                p {
                    font-size: 18px;
                }
            }
        }
    }
    .relevant-expert-ins-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .relevant-expert-wrap {
            .relevant-expert {
                padding-top: 14px;
                display: flex;
                // justify-content: space-between;levant-ins

                .relevant-expert-img {
                    margin-right: 13px;
                    img {
                        width: 106px;
                        height: 142px;
                        cursor: pointer;
                        box-shadow: 0px 3px 6px 0px rgba(15, 11, 5, 0.29);
                    }
                }
                .relevant-expert-info {
                    h5 {
                        font-size: 18px;
                        line-height: 18px;
                        margin-bottom: 8px;
                        cursor: pointer;
                    }
                    p {
                        color: #666;
                        line-height: 28px;
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4; // 控制多行的行数
                        -webkit-box-orient: vertical;
                    }
                }
            }
            ul {
                li {
                    width: 136px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    position: relative;
                    font-size: 18px;
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    position: absolute;
                    left: 0;
                    top: 12px;
                    background: #ffac13;
                }
            }
        }
        .relevant-ins-wrap {
            .relevant-ins {
                padding-top: 14px;
                display: flex;
                // justify-content: space-between;
                .relevant-ins-img {
                    margin-right: 13px;
                    img {
                        width: 106px;
                        height: 142px;
                        cursor: pointer;
                        box-shadow: 0px 3px 6px 0px rgba(15, 11, 5, 0.29);
                    }
                }
                .relevant-ins-info {
                    h5 {
                        font-size: 18px;
                        line-height: 18px;
                        margin-bottom: 8px;
                        cursor: pointer;
                    }
                    p {
                        color: #666;
                        line-height: 28px;
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4; // 控制多行的行数
                        -webkit-box-orient: vertical;
                    }
                }
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 182px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    position: relative;
                    font-size: 18px;
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    position: absolute;
                    left: 0;
                    top: 12px;
                    background: #ffac13;
                }
            }
        }
        .com-relevant {
            width: 588px;
            height: 298px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 0 20px;
            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: 8px;
                li {
                    margin-bottom: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-top: 28px;
    }
}
</style>